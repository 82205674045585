<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/knowledge-base' }">
        <app-i18n code="entities.knowledgeBase.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.knowledgeBase.edit.title" v-if="isEditing"></app-i18n>
        <app-i18n code="entities.knowledgeBase.new.title" v-if="!isEditing"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.knowledgeBase.edit.title" v-if="isEditing"></app-i18n>
        <app-i18n code="entities.knowledgeBase.new.title" v-if="!isEditing"></app-i18n>
      </h1>

      <div class="app-page-spinner" v-if="findLoading" v-loading="findLoading"></div>
      
      <app-knowledge-base-form
        :isEditing="isEditing"
        :record="record"
        :saveLoading="saveLoading"
        @cancel="doCancel"
        @submit="doSubmit"
        v-if="!findLoading"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import KnowledgeBaseForm from '@/modules/knowledge-base/components/knowledge-base-form';
import { routerAsync } from '@/app-module';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { i18n } from '@/i18n';

export default {
  name: 'app-knowledge-base-form-page',

  props: ['id'],

  components: {
    [KnowledgeBaseForm.name]: KnowledgeBaseForm,
  },

  computed: {
    ...mapGetters({
      record: 'knowledgeBase/form/record',
      findLoading: 'knowledgeBase/form/findLoading',
      saveLoading: 'knowledgeBase/form/saveLoading',
      isSaved: 'knowledgeBase/form/isSaved',
      pendingFiles: 'knowledgeBase/form/pendingFiles',
    }),

    isEditing() {
      return !!this.id;
    },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
  },

  async beforeRouteLeave(to, from, next) {
     //if status is completed we skip the dialog check and navigate to next page
    if (this.isSaved || to.name.includes("error")) {
      next();
      return;
    }
    
    try {
      await this.$confirm(
        i18n('common.discardFormConfirm'),
        i18n('common.confirm'),
        {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
          closeOnClickModal: false,
          closeOnPressEscape: false,
        },
      );

      let response;
      if (this.pendingFiles.length > 0) {
        response = await FileUploader.removePendingFiles(
          this.pendingFiles,
        );
        if (response === 200) next();
      }

      next();
    } catch (error) {
      next(false);
    }
  },

  methods: {
    ...mapActions({
      doFind: 'knowledgeBase/form/doFind',
      doNew: 'knowledgeBase/form/doNew',
      doUpdate: 'knowledgeBase/form/doUpdate',
      doCreate: 'knowledgeBase/form/doCreate',
    }),

    doCancel() {
      routerAsync().push('/knowledge-base');
    },

    async doSubmit(payload) {
      if (this.isEditing) {
        return this.doUpdate(payload);
      } else {
        return this.doCreate(payload.values);
      }
    },
  },
};
</script>

<style>
</style>
